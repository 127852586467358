<template>
    <div>
        <div align="center">
            <h4 class="q-mb-sm">Scadenze Semestrali</h4>
            Estrae tutte le quietanze in scadenza semestrale dal giorno al giorno selezionato
            <br><br>
            <hr>
        </div>
        <div class="row justify-center">

            <div class="col-12 col-md-5 q-mr-sm q-mt-sm justify-center">
                <q-input outlined
                    v-model="dal_giorno"
                    mask="##/##/####"
                    label="Dal giorno">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="dal_giorno"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <br>
                <q-input outlined
                    v-model="al_giorno"
                    mask="##/##/####"
                    label="Al giorno">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="al_giorno"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
                <br>
            <!--    <q-select
                    emit-value
                    map-options
                    outlined
                    v-model="compagnia"
                    :options="elenco_compagnie"
                    label="Compagnia"
                /> //-->
            </div>

            <div class="col-12 col-md-5 q-mr-sm justify-center">
                <fieldset>
                    <legend>&nbsp;Selezione rapida&nbsp;</legend>

                    <q-btn flat label="Questa Settimana" icon="mdi-download-network-outline" @click.native="onQuestaSettimana" /><br>
                    <q-btn flat label="Questo Mese"  icon="mdi-download-network-outline" @click.native="onQuestoMese" /><br>
                    <q-btn flat label="Settimana prossima"  icon="mdi-download-network-outline" @click.native = "onSettimanaProssima" /><br>
                    <q-btn flat label="Mese prossimo"  icon="mdi-download-network-outline" @click.native="onMeseProssimo" /><br>
                    <q-btn flat label="Prossimi 15 giorni"  icon="mdi-download-network-outline" @click.native="onProssimi15giorni" /><br>
                    <q-btn flat label="Prossimi 30 giorni"  icon="mdi-download-network-outline" @click.native="onProssimi30giorni" />

                </fieldset>
            </div>

            <div class="col-12 col-md-12" align="center">
                <br>
                <q-btn color="primary" label="Cerca" @click.native="onCercaNuovaProduzione"/>
                <br>
            </div>

            <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
                v-show="!is_loading"
                style="z-index: 1000;">
                <q-spinner-hourglass
                    size="4em"
                />
            </div>

            <div class="col-12 col-md-12">
                <q-table
                    title=""
                    :data="rows"
                    :columns="colonne"
                    color="primary"
                    row-key="name"
                    flat
                    :visible-columns="visibleColumns"
                    >
                    <template v-slot:top-right>
                        <q-btn
                        color="primary"
                        icon-right="archive"
                        label="ESPORTA IN CSV"
                        no-caps
                        @click="esportaTabella"
                        />
                    </template>
                </q-table>
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />
                </div>

            </div>

        </div>
    </div>
</template>
<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex';
    import { exportFile } from 'quasar';
    import QQButton from "@/components/QQButton.vue";
    //import commonLib from "@/libs/commonLib.js";

    function wrapCsvValue (val, formatFn) {
        let formatted = formatFn !== void 0
            ? formatFn(val)
            : val

        formatted = formatted === void 0 || formatted === null
            ? ''
            : String(formatted)

        formatted = formatted.split('"').join('""')
        return `"${formatted}"`
    }

    export default {
        name: "ScadenzeContrattuali",
        components: {
            QQButton
        },
        data() {
            return {
                dal_giorno: "",
                al_giorno: "",
                visibleColumns: ['nominativo','area','unita_operativa','numero','compagnia','scadenza','premio_tariffa','premio_garantito','provvigioni'],
                mese_estrazione: "",
                elenco_compagnie: [],
                compagnia: "tutti",
            }
        },
        methods: {
            ...mapActions({
                fetchScadenzeSemestrali: "gestioneEstrazioni/fetchScadenzeSemestrali",
                fetchElencoCompagnie: "gestioneCatalogoProdotti/fetchElencoCompagnie"
            }),
            esportaTabella() {
                // naive encoding to csv format
                const content = [ this.colonne.map(col => wrapCsvValue(col.label)) ].concat(
                    this.rows.map(row => this.colonne.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === void 0 ? col.name : col.field],
                    col.format
                    )).join(','))
                ).join('\r\n')

                const status = exportFile(
                    'scadenze-semestrali-'+this.dal_giorno.replaceAll(/_/g,"_")+'_'+this.al_giorno.replaceAll("/","_")+'.csv',
                    content,
                    'text/csv'
                )

                if (status !== true) {
                    this.$q.notify({
                    message: 'Browser denied file download...',
                    color: 'negative',
                    icon: 'warning'
                    })
                }
            },
            async onCercaNuovaProduzione() {
                if ((!this.dal_giorno === "") || (this.al_giorno === "")) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi valorizzare i campi DAL GIORNO e AL GIORNO prima di richiedere una ricerca'
                    });
                    return;
                }

                await this.fetchScadenzeSemestrali({DalGiorno: this.dal_giorno,AlGiorno: this.al_giorno, compagnia: this.compagnia});

                if (this.rows.length === 0) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun dato identificato'
                    });
                }
            },
            onIndietroClicked() {
                this.$router.push({name : "Estrazioni"});
            },
            onQuestaSettimana() {
                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6

                var firstday = new Date(curr.setDate(first)); //.toUTCString();
                var lastday = new Date(curr.setDate(last)); //.toUTCString();
                var giorno = firstday.getDate()+"";
                var mese = (firstday.getMonth()+1)+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = ((lastday.getMonth()+1)+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onQuestoMese() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onSettimanaProssima() {
                var oggi = new Date();
                var curr = new Date(oggi.getFullYear(), oggi.getMonth(), oggi.getDate()+7);
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6

                var firstday = new Date(curr.setDate(first)); //.toUTCString();
                var lastday = new Date(curr.setDate(last)); //.toUTCString();
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onMeseProssimo() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                var lastday = new Date(date.getFullYear(), date.getMonth() + 2, 0);
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onProssimi15giorni() {
                var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                var lastday = new Date(date.getFullYear(), date.getMonth() , date.getDate() + 15);
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            },
            onProssimi30giorni() {
                 var date = new Date();
                var firstday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                var lastday = new Date(date.getFullYear(), date.getMonth() , date.getDate() + 30);
                var giorno = firstday.getDate()+"";
                var mese = firstday.getMonth()+1+"";
                var anno = firstday.getFullYear();

                this.dal_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;

                giorno = (lastday.getDate()+"").padStart(2,'0');
                mese   = (lastday.getMonth()+1+"").padStart(2,'0');
                anno   = lastday.getFullYear();

                this.al_giorno = giorno.padStart(2,'0')+"/"+mese.padStart(2,'0')+"/"+anno;
            }
        },
        computed: {
            ...mapFields("gestioneEstrazioni", {
                colonne: "colonne",
                rows: "righe"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                //colonne: state => state.gestioneEstrazioni.colonne,
                //rows: state => state.gestioneEstrazioni.righe,
                is_loading: state => state.gestioneEstrazioni.is_loading
            })
        },
        async mounted() {
            this.elenco_compagnie = await this.fetchElencoCompagnie(true);
            this.elenco_compagnie.unshift( { label: "VISUALIZZA TUTTO", value: "tutti" });
        },
        activated()
        {
           this.colonne = [];
           this.rows = [];
        }
    }
</script>
